import React, { useState } from 'react';
import logo from '../images/brand/fieldDayFlorals-logo-big.png';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import '../index.css';

export default function NavBar() {
  const [expanded, setExpanded] = useState(false);
  const getNavLinkClass = (path) => {
    return window.location.pathname === path ? 'NavBarLink active' : 'NavBarLink';
  };

  return (
      <Navbar className='navBar sticky-top' expand="md" expanded={expanded}>
        <Container>
          <Navbar.Brand href="/" className="ms-0">
            <img src={logo} className="logoPicture" alt="Logo" />
          </Navbar.Brand>
          <Navbar.Toggle onClick={() => setExpanded(expanded => !expanded)} />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-0"> {/* Use "ms-0" to align to the left */}
              <NavLink to="/about" className={() => getNavLinkClass("/about")}>About</NavLink>
              <NavLink to="/services" className={() => getNavLinkClass("/services")}>Services</NavLink>
              <NavLink to="/portfolio" className={() => getNavLinkClass("/portfolio")}>Portfolio</NavLink>
              <NavLink to="/contact" className={() => getNavLinkClass("/contact")}>Contact</NavLink>
              {/*<NavLink to="/valentines" className={() => getNavLinkClass("/valentines")}>VALENTINE'S DAY</NavLink>*/}
              {/*<NavLink to="/mothersday" className={() => getNavLinkClass("/mothersday")}>Mother's Day</NavLink>*/}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
  );
}
