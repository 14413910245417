import AboutApril from '../components/AboutApril';
import Testimonial from "../components/Testimonials";
import GoToTop from "../components/GoToTop";
import TestimonialOne from "../images/brand/Wedding-Hero.png";


export default function AboutPage() {
    return(
        <div>
            <AboutApril/>
            <Testimonial
                image={TestimonialOne}
                altText="Client's Testimonial"
                testimonialText="April was the florist for our wedding celebration held in the Redwood Grove of the San Francisco Botanical Gardens. Working with her was an absolute pleasure. April understood our vision for the flowers right away and was so skilled at making our day look and feel exactly how we hoped. All of our guests commented on how beautiful and joyful the flowers were throughout the ceremony and reception. She set up the arch and flower arrangements for both the ceremony and our dinner following, making the day feel smooth and stress-free. I loved my bouquet so much I’m getting it dried and framed! We couldn’t recommend her highly enough!"
                author="Megan"
                highlight="April understood our vision for the flowers right away and was so skilled at making our day look and feel exactly how we hoped."
            />
            <GoToTop/>
        </div>
    )
}