import React from 'react';
import ContactMe from '../components/ContactForm'
import DahliaBouquet from '../images/gallery/arrangements/final/arrangement-vase-dahlia-20230601.jpg'
import GoToTop from "../components/GoToTop";

export default function ContactPage() {
    return(
        <section className = "container p-5 contactPage">
            <div className ="row">
                <div className = "col-12 col-md-6 d-flex justify-content-center align-items-center pb-4">
                    <img
                        src={DahliaBouquet}
                        className="contactMeImage img-fluid"
                        alt="Dahlia Bouquet for contact page"
                    />
                </div>
                <div className="col-12 col-md-6 d-flex justify-content-center align-items-center">
                    <ContactMe/>
                </div>
            </div>
            <GoToTop/>
        </section>
    )
}