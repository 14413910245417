import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';

export default function ContactMe() {
    const form = useRef();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [errors, setErrors] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const fields = {
            from_name: 'Name',
            user_email: 'Email',
            event_date: 'Event date',
            event_venue: 'Event location & venue',
            guest_count: 'Guest count',
            floral_budget: 'Floral budget',
            message: 'Message',
            referral_source: 'Referral source'
        };

        Object.entries(fields).forEach(([field, label]) => {
            if (!form.current[field].value.trim()) {
                newErrors[field] = `${label} is required`;
            }
        });

        if (!/\S+@\S+\.\S+/.test(form.current.user_email.value.trim())) {
            newErrors.user_email = 'Email is invalid';
        }

        const guestCount = parseInt(form.current.guest_count.value);
        if (isNaN(guestCount) || guestCount < 1) {
            newErrors.guest_count = 'Please enter a valid guest count';
        }

        const selectedDate = new Date(form.current.event_date.value);
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        if (selectedDate < today) {
            newErrors.event_date = 'Event date must be in the future';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const sendEmail = (e) => {
        e.preventDefault();

        if (validateForm()) {
            setIsSubmitting(true);

            emailjs.sendForm('service_ab844fp', 'template_goxe50a', form.current, '_SImmCTWEBPBz4gSR')
                .then((result) => {
                    setIsSubmitted(true);
                    form.current.reset();
                }, (error) => {
                    alert("Failed to send the message, please try again.");
                })
                .finally(() => {
                    setIsSubmitting(false);
                });
        }
    };

    return (
        <section id="contact" className="bt-5 formFont contactForm">
            <h1 className="text-center contactFormHeading">Let's Work Together!</h1>
            <p className="text-center text-paragraph">
                Thank you for your interest, we're excited about your event! Please fill out the form below, and we'll get back to you within two business days.
            </p>
            {isSubmitted ? (
                <div className="alert alert-success" role="alert">
                    Your message has been sent!
                </div>
            ) : (
                <form ref={form} onSubmit={sendEmail} className="formDiv">
                    <div className="form-group">
                        <label htmlFor="from_name">Name</label>
                        <input
                            type="text"
                            id="from_name"
                            name="from_name"
                            className={`form-control mb-2 ${errors.from_name ? 'is-invalid' : ''}`}
                            placeholder="Name"
                        />
                        {errors.from_name && <div className="invalid-feedback">{errors.from_name}</div>}
                    </div>

                    <div className="form-group">
                        <label htmlFor="user_email">Email</label>
                        <input
                            type="email"
                            id="user_email"
                            name="user_email"
                            className={`form-control mb-2 ${errors.user_email ? 'is-invalid' : ''}`}
                            placeholder="Email"
                        />
                        {errors.user_email && <div className="invalid-feedback">{errors.user_email}</div>}
                    </div>

                    <div className="form-group">
                        <label htmlFor="event_date">Event Date</label>
                        <input
                            type="date"
                            id="event_date"
                            name="event_date"
                            className={`form-control mb-2 ${errors.event_date ? 'is-invalid' : ''}`}
                        />
                        {errors.event_date && <div className="invalid-feedback">{errors.event_date}</div>}
                    </div>

                    <div className="form-group">
                        <label htmlFor="event_venue">Event Location & Venue</label>
                        <input
                            type="text"
                            id="event_venue"
                            name="event_venue"
                            className={`form-control mb-2 ${errors.event_venue ? 'is-invalid' : ''}`}
                            placeholder="Event Location & Venue"
                        />
                        {errors.event_venue && <div className="invalid-feedback">{errors.event_venue}</div>}
                    </div>

                    <div className="form-group">
                        <label htmlFor="guest_count">Event Guest Count</label>
                        <input
                            type="number"
                            id="guest_count"
                            name="guest_count"
                            min="1"
                            className={`form-control mb-2 ${errors.guest_count ? 'is-invalid' : ''}`}
                            placeholder="Number of Guests"
                        />
                        {errors.guest_count && <div className="invalid-feedback">{errors.guest_count}</div>}
                    </div>

                    <div className="form-group">
                        <label htmlFor="floral_budget">Floral / Decor Budget</label>
                        <input
                            type="text"
                            id="floral_budget"
                            name="floral_budget"
                            className={`form-control mb-2 ${errors.floral_budget ? 'is-invalid' : ''}`}
                            placeholder="Budget Range"
                        />
                        {errors.floral_budget && <div className="invalid-feedback">{errors.floral_budget}</div>}
                    </div>

                    <div className="form-group">
                        <label htmlFor="message">Tell us what you're looking for:</label>
                        <textarea
                            id="message"
                            name="message"
                            className={`form-control ${errors.message ? 'is-invalid' : ''}`}
                            placeholder="Number of arrangements, color palette, etc."
                            rows="7"
                        ></textarea>
                        {errors.message && <div className="invalid-feedback">{errors.message}</div>}
                    </div>

                    <div className="form-group">
                        <label htmlFor="referral_source">How did you hear about us?</label>
                        <input
                            type="text"
                            id="referral_source"
                            name="referral_source"
                            className={`form-control mb-2 ${errors.referral_source ? 'is-invalid' : ''}`}
                            placeholder="Let us know!"
                        />
                        {errors.referral_source && <div className="invalid-feedback">{errors.referral_source}</div>}
                    </div>

                    <button
                        type="submit"
                        className="aboutButton"
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? 'Sending...' : 'Send Message'}
                    </button>
                </form>
            )}
        </section>
    );
}
